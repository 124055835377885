<template>
<div>
  <div style="border-bottom: 2px dotted #111111;font: normal normal 18px 'trebuchet ms', georgia, arial;color: #686667;margin-bottom:30px">
    Profile
  </div>
  <p>Enter your current password if you wish to modify any of your account's details. </p>
  <table border="0" cellpadding="0" cellspacing="0" style="width:80%;border: 1px solid #C3C1C2;border-collapse:separate;" class="ifoldrnatable">
    <tbody>
      <tr>
        <td width="60%">Username:</td>
        <td><input id="username" type="text" name="username" style="width: 200px; cursor: auto;" maxlength="10" autocomplete="off" v-model="user.username"></td>
      </tr>
      <tr>
        <td>Password:</td>
        <td><input id="password" type="password" name="password" style="width: 200px;" maxlength="15" autocomplete="off" v-model="user.password"/></td>
      </tr>
      <tr>
        <td>First Name:</td>
        <td><input id="namefirst" type="text" name="namefirst" style="width:200px" maxlength="15" v-model="user.firstname" /></td>
      </tr>
      <tr>
        <td>Last Name:</td>
        <td><input id="namelast" type="text" name="namelast" style="width:200px" maxlength="15" v-model="user.lastname"/></td>
      </tr>
      <tr>
        <td>Email:</td>
        <td><input id="email" type="text" name="email" style="width:200px" maxlength="60" v-model="user.email"></td>
      </tr>
      <tr>
        <td>Organization:</td>
        <td><input id="organization" type="text" name="organization" style="width:200px" maxlength="20" v-model="user.affiliation"></td>
      </tr>
      <!--
      <tr>
        <td colspan="2" align="center"> <input type="Submit" value="Update" style="width:150px" @click="updateUser"> <input type="Submit" value="Delete Account" style="width:150px" @click="deleteUser"></td>
      </tr>
      -->
    </tbody>
  </table>
</div>

</template>

<script>
// import { bus } from '../bus.js'
// import axios from 'axios'

export default {
  name: 'Profile',

  data () {
    return {
      user: {}
    }
  },

  methods: {
    updateUser () {
    },

    deleteUser () {
    }
  },

  mounted () {
    this.user = this.$store.state.user
    console.log(this.user)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.ifoldrnatable * {
  font: normal 12px "Trebuchet MS", Verdana, Arial, Helvetica, sans-serif;
}

.ifoldrnatable tr {
  height: 20px;
}

.ifoldrnatable tr td {
  background: #fff;
  border-right: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  color: #686868;
  padding: 5px;
  /* max-height: 20px; */
  max-width: 50px;
  word-wrap: break-word;
  word-break: break-all;
}

</style>

