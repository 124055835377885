<template>
<div class="taskmanagement">
  <div v-if="showTasks">
    <table class="tasks">
      <thead>
        <th>Task ID</th>
        <th>User</th>
        <th>Title</th>
        <th>Submit Time</th>
        <th>Process Time</th>
        <th>Finish Time</th>
        <th>Status</th>
      </thead>
      <tbody class="tasks">
        <tr v-for="(task,itask) in tasks" :key="itask">
          <!--
          <td><a :href="'#/Task/'+task.id">{{ task.id }}</a></td>
          -->
          <td>{{ task.id }}</td>
          <td>{{`${task.userid}`==='0' ? 'Guest' : task.userid}}</td>
          <td>{{'title' in task ? task.title : ''}}</td>
          <td v-text="showTime(task.tsubmit)"></td>
          <td v-text="showTime(task.tprocess)"></td>
          <td v-text="showTime(task.tfinish)"></td>
          <td>
            <div v-if="task.status==='queued'"><i>Queued</i></div>
            <div v-else-if="task.status==='failed'"><i>Failed</i></div>
            <div v-else-if="task.status==='finished'">
              Done <i v-if="`${task.userid}`==='0' || `${$store.state.user.id}`===`${task.userid}` || `${$store.state.user.level}`===`10`" class="el-icon-view" @click="$store.dispatch('direct',`#/Task/${task.id}`)" style="cursor:pointer"></i>
            </div>
            <div v-html="finishedStatus(task.id)" v-else-if="task.status==='finished'"></div>
            <div v-else><i style="margin-left: 10px" class="el-icon-loading"></i></div>
          </td>
        </tr>
      </tbody>
    </table>
    <el-pagination background layout="prev, pager, next" @current-change="page=$event;check(false)" :page-count="npages"></el-pagination>
  </div>
</div>
</template>

<script>
import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'

export default {
  name: 'Queue',
  data () {
    return {
      showTasks: false,
      runningStatus: '<i style="margin-left: 10px" class="el-icon-loading"></i>',
      tasks: [],
      intervalId: 0,
      page: 1,
      npages: 0,
      server: 'cpi'
    }
  },
  methods: {
    showTime (timestamp) {
      let date = new Date(timestamp * 1000)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let hour = date.getHours()
      let minute = date.getMinutes()
//        date.getSeconds(),
      return timestamp ? `${year}-${month}-${day} ${hour}:${minute}` : '-'
    },

    finishedStatus (id) {
      return '<a href="#/Task/' + id + '"><i style="color:#878d99; margin-left: 10px;" class="view el-icon-view"></i></a>'
    },

    check (doContinue) {
      let next = () => {
        if (doContinue) {
          setTimeout(() => {
            this.check(true)
          }, 3000)
        }
      }

      if (this.$route.name === 'Queue') {
        axios({
          method: 'get',
          withCredentials: true,
          url: `${this.$store.state.host}/dokhlab/actions/queue?server=${this.$store.state.server}&page=${this.page}`
        }).then(response => {
          let r = response.data
          this.tasks = r.tasks
          this.npages = r.pages
          this.tasks.sort((a, b) => {
            return b.tsubmit - a.tsubmit
          })
          this.showTasks = true
        }).catch(() => {
          console.log('get failed')
        }).finally(() => {
          next()
        })
      } else {
        next()
      }
    }
  },

  mounted () {
    this.check(true)
  },

  destroyed () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.taskmanagement {
  margin-left: 10px;
  height: 100%;
}

table.tasks {
  text-align: center;
  color: rgb(96, 98, 102);
  margin: 30px 0px;
}

thead {
  display: block;
}

tbody.tasks {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

th {
  color: #878d99;
}

th {
  padding: 12px 12px 0px 12px;
  width: 120px;
}

td {
  padding: 12px;
  width: 120px;
}

a {
  color: rgb(144, 147, 153);
  text-decoration: None;
}

</style>
