import Vue from 'vue'
import Router from 'vue-router'
import Profile from '../components/Profile'
import Help from '../components/Help'
// import Users from '../components/Users'
import Submit from '../components/Submit'
import Search from '../components/Search'
import Queue from '../../Queue'
import Task from '../components/Task'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/submit'
    },

    {
      path: '/profile',
      name: 'Profile',
      component: Profile
    },

    {
      path: '/help',
      name: 'Help',
      component: Help
    },

//    {
//      path: '/users',
//      name: 'Users',
//      component: Users
//    },

    {
      path: '/submit',
      name: 'Submit',
      component: Submit
    },

    {
      path: '/search',
      name: 'Search',
      component: Search
    },

    {
      path: '/queue',
      name: 'Queue',
      component: Queue
    },

    {
      path: '/task/:id',
      name: 'Task',
      component: Task
    }
  ]
})
