<template>
<div>
  <!--
  <el-dialog :visible.sync="submitResultVisible" width="50%">
    <el-row v-for="(error, i) in errors" :key="i" class="error-row">
      <el-col :span="3">
        <b style="color:red">Error {{i+1}}:</b>
      </el-col>
      <el-col :span="18">
        <span v-html="error"></span>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button type="info" @click="submitResultVisible=false">Close</el-button>
    </span>
  </el-dialog>
  -->

  <div style="border-bottom: 2px dotted #111111;font: normal normal 18px 'trebuchet ms', georgia, arial;color: #686667;margin-bottom:30px">
    Submit a task
  </div>

  <h4>Step 1: Select the Type of Task</h4>
  <div>

    <!-- RNA Structure Prediction (with RNA sequence only) -->
    <div v-if="taskType===-1||taskType===0">
      <!-- Step 1 -->
      <div v-if="taskType===-1||taskType===0">
        <!-- Step 1 Head -->
        <div @click="showTypeContent=0" class="step-box-header">
          1. RNA Modeling
          <div class="step-box-button">
            <a v-if="taskType===-1" @click="taskType=0">Choose this task</a>
            <a v-if="taskType===0" @click="taskType=-1">Choose a different task</a>
          </div>
          <div class="clear:both"></div>
        </div>

        <!-- Step 1 Content -->
        <div v-if="showTypeContent===0" class="step-box-content">
          In RNA structure prediction, folding starts from a linear conformation of the protein at the initial temperature given, and the temperature is reduced at the specified rate until the system reaches the final temperature. The user may also specify the RNA sequence using a FASTA/NDB file format and replica exchahnge folding simulation is performed starting from a linear RNA conformation.
        </div>
      </div>

      <div v-if="taskType===0">
        <h4>Step 2: Please fill these inputs:</h4>
        <div class="step-table">
          <table>
            <tr><td style="width:60%">Task title (Optional)</td><td><input v-model="taskConfig[0].title"></td></tr>

            <tr>
              <td style="width:60%">RNA Sequence (A/C/G/U only)</td><td><input v-model="taskConfig[0].seq">
                <p><b>Example</b>: ACGUACCGU</p>
                <p>Only 'A', 'U', 'G', 'C' are accepted. <span style="color:#b10808">'T' is not accepted!!!</span></p>
                <p>Lowercase is not accepted.</p>
              </td>
            </tr>

            <tr>
              <td>RNA Secondary Structure (Optional)</td>
              <td><input v-model="taskConfig[0].ss">
                <p>Please use Dot-bracket Notation. All parentheses and brackets should be paired. Pseudo-knots are supported.</p>
                <p>
                  <b>Correct Example 1</b>: (((...))) <br>
                  <b>Correct Example 2</b>: (((((..[[[)))))..]]]<br>
                  <b style="color:#b10808">Wrong Example</b>: (((...]]]
                </p>
              </td>
            </tr>
            <tr><td>Simulation Time:</td><td><input v-model="taskConfig[0].simTime"></td></tr>
            <tr><td>Replica 1 Temperature (DMD units):</td><td><input v-model="taskConfig[0].repT[0]"></td></tr>
            <tr><td>Replica 2 Temperature (DMD units):</td><td><input v-model="taskConfig[0].repT[1]"></td></tr>
            <tr><td>Replica 3 Temperature (DMD units):</td><td><input v-model="taskConfig[0].repT[2]"></td></tr>
            <tr><td>Replica 4 Temperature (DMD units):</td><td><input v-model="taskConfig[0].repT[3]"></td></tr>
            <tr><td>Replica 5 Temperature (DMD units):</td><td><input v-model="taskConfig[0].repT[4]"></td></tr>
            <tr><td>Replica 6 Temperature (DMD units):</td><td><input v-model="taskConfig[0].repT[5]"></td></tr>
            <tr><td>Replica 7 Temperature (DMD units):</td><td><input v-model="taskConfig[0].repT[6]"></td></tr>
            <tr><td>Replica 8 Temperature (DMD units):</td><td><input v-model="taskConfig[0].repT[7]"></td></tr>
            <tr><td>Replica Exchange Interval (DMD units):</td><td><input v-model="taskConfig[0].repInt"></td></tr>
            <tr><td>Heat Exchange Coefficient (Berendsen Thermostat, DMD Units):</td><td><input v-model="taskConfig[0].heatExchCoeff"></td></tr>
          </table>
        </div>
      </div>

      <!-- Step 3 -->
      <!--
      <div v-if="taskType===0">
        Step 3: Select your outputs:
        <div>
          <table>
            <tr><td>Predicted RNA structures: </td><td><input type="checkbox" v-model="taskConfig[0].predStruct"></td></tr>
            <tr><td>Number of energy-minimized structures to predict: </td><td></td></tr>
            <tr><td>Free energies of predicted structures (tabulated):</td><td></td></tr>
            <tr><td>Binary trajectory data files:</td><td></td></tr>
            <tr><td>Echo-data files: periodic energy, Rg etc.: </td><td></td></tr>
            <tr><td>Periodic trajectory snapshots: rawFiles.zip (Replica Exchange files, *.rxo).:</td><td></td></tr>
          </table>
        </div>
      </div>
      -->
    </div>

    <!-- Thermodynamic Analysis -->
    <div v-if="taskType===-1||taskType===1">
      <!-- Step 1 -->
      <div>
        <!-- Step 1 Head -->
        <div @click="showTypeContent=1" class="step-box-header" v-if="taskType===-1||taskType===1">
          2. RNA Thermodynamic Analysis
          <div class="step-box-button">
            <a v-if="taskType===-1" @click="taskType=1">Choose this task</a>
            <a v-if="taskType===1" @click="taskType=-1">Choose a different task</a>
          </div>
        </div>

        <!-- Step 1 Content -->
        <div class="step-box-content" v-if="taskType===1||showTypeContent===1">
          In Thermodynamic analysis mode, various thermodynamic properties of RNA folding is inferred using replica-exchange DMD simulation.
        </div>
      </div>

      <!-- Step 2 -->
      <div v-if="taskType===1">
        <h4>Step 2: Please fill these inputs:</h4>
        <div class="step-table">
          <table>
            <tr><td style="width:60%">Task title (Optional)</td><td><input v-model="taskConfig[1].title"></td></tr>
            <tr><td style="width:60%">Upload RNA Structure File (NDB Format)</td><td><input type="file" ref="pdb"></td></tr>
            <tr><td>Simulation Time:</td><td><input v-model="taskConfig[1].simTime"></td></tr>
            <tr><td>Replica 1 Temperature (DMD units):</td><td><input v-model="taskConfig[1].repT[0]"></td></tr>
            <tr><td>Replica 2 Temperature (DMD units):</td><td><input v-model="taskConfig[1].repT[1]"></td></tr>
            <tr><td>Replica 3 Temperature (DMD units):</td><td><input v-model="taskConfig[1].repT[2]"></td></tr>
            <tr><td>Replica 4 Temperature (DMD units):</td><td><input v-model="taskConfig[1].repT[3]"></td></tr>
            <tr><td>Replica 5 Temperature (DMD units):</td><td><input v-model="taskConfig[1].repT[4]"></td></tr>
            <tr><td>Replica 6 Temperature (DMD units):</td><td><input v-model="taskConfig[1].repT[5]"></td></tr>
            <tr><td>Replica 7 Temperature (DMD units):</td><td><input v-model="taskConfig[1].repT[6]"></td></tr>
            <tr><td>Replica 8 Temperature (DMD units):</td><td><input v-model="taskConfig[1].repT[7]"></td></tr>
            <tr><td>Replica Exchange Interval (DMD units):</td><td><input v-model="taskConfig[1].repInt"></td></tr>
            <tr><td>Heat Exchange Coefficient (Berendsen Thermostat, DMD Units):</td><td><input v-model="taskConfig[1].heatExchCoeff"></td></tr>
          </table>
        </div>
      </div>

      <!-- Step 3 -->
      <!--
      <div v-if="taskType===1">
        Step 3: Select your outputs:
        <div>
          <table>
            <tr><td>Data for RMS deviations of trajectory snapshots from input structure:</td><td></td></tr>
            <tr><td>Number of energy-minimized structures to predict: </td><td></td></tr>
            <tr><td>Free energies of predicted structures (tabulated):</td><td></td></tr>
            <tr><td>Binary trajectory data files:</td><td></td></tr>
            <tr><td>Echo-data files: periodic energy, Rg etc.: </td><td></td></tr>
            <tr><td>Periodic trajectory snapshots: rawFiles.zip (Replica Exchange files, *.rxo).:</td><td></td></tr>
          </table>
        </div>
      </div>
      -->
    </div>

    <!-- RNA Structure Refinement Using Constraints -->
    <div v-if="taskType===-1||taskType===2">
      <!-- Step 1 -->
      <div>
        <!-- Step 1 Head -->
        <div @click="showTypeContent=2" class="step-box-header" v-if="taskType===-1||taskType===2">
          3. RNA Modeling with HRP
          <div class="step-box-button">
            <a v-if="taskType===-1" @click="taskType=2">Choose this task</a>
            <a v-if="taskType===2" @click="taskType=-1">Choose a different task</a>
          </div>
        </div>

        <!-- Step 1 Content -->
        <div class="step-box-content" v-if="taskType===2||showTypeContent===2">
          Refinement of RNA structure using constraints information, such as base pairing and long-range constraints.
        </div>
      </div>

      <!-- Step 2 -->
      <div v-if="taskType===2">
        <h4>Step 2: Please fill these inputs:</h4>
        <div class="step-table">
          <table>
            <tr><td style="width:60%">Task title (Optional)</td><td><input v-model="taskConfig[2].title"></td></tr>
            <tr><td style="width:60%">RNA Sequence (A/C/G/U only):</td><td><input v-model="taskConfig[2].seq">
                <p><b>Example</b>: ACGUACCGU</p>
                <p>Only 'A', 'U', 'G', 'C' are accepted. <span style="color:#b10808">'T' is not accepted!!!</span></p>
                <p>Lowercase is not accepted.</p>
            </td></tr>
            <tr>
              <td>RNA Secondary Structure (Optional)</td>
              <td><input v-model="taskConfig[2].ss">
                <p>Please use Dot-bracket Notation. All parentheses and brackets should be paired. Pseudo-knots are supported.</p>
                <p>
                  <b>Correct Example 1</b>: (((...))) <br>
                  <b>Correct Example 2</b>: (((((..[[[)))))..]]]<br>
                  <b style="color:#b10808">Wrong Example</b>: (((...]]]
                </p>
              </td>
            </tr>
            <tr><td>Upload Hydroxyl Radical Probing File (Optional):</td><td><input type="file" ref="hrp">
                <p>Click <b><a :href="$store.state.host + '/ifoldrna/examples/MBox.hrp'">here</a></b> to download example HRP file.</p>
            </td></tr>
            <tr><td>Simulation Time:</td><td><input v-model="taskConfig[2].simTime"></td></tr>
            <tr><td>Replica 1 Temperature (DMD units):</td><td><input v-model="taskConfig[2].repT[0]"></td></tr>
            <tr><td>Replica 2 Temperature (DMD units):</td><td><input v-model="taskConfig[2].repT[1]"></td></tr>
            <tr><td>Replica 3 Temperature (DMD units):</td><td><input v-model="taskConfig[2].repT[2]"></td></tr>
            <tr><td>Replica 4 Temperature (DMD units):</td><td><input v-model="taskConfig[2].repT[3]"></td></tr>
            <tr><td>Replica 5 Temperature (DMD units):</td><td><input v-model="taskConfig[2].repT[4]"></td></tr>
            <tr><td>Replica 6 Temperature (DMD units):</td><td><input v-model="taskConfig[2].repT[5]"></td></tr>
            <tr><td>Replica 7 Temperature (DMD units):</td><td><input v-model="taskConfig[2].repT[6]"></td></tr>
            <tr><td>Replica 8 Temperature (DMD units):</td><td><input v-model="taskConfig[2].repT[7]"></td></tr>
            <tr><td>Replica Exchange Interval (DMD units):</td><td><input v-model="taskConfig[2].repInt"></td></tr>
            <tr><td>Heat Exchange Coefficient (Berendsen Thermostat, DMD Units):</td><td><input v-model="taskConfig[2].heatExchCoeff"></td></tr>
          </table>
        </div>
      </div>

    </div>


    <!-- RNA Structure Refinement Using NMR -->
    <div v-if="taskType===-1||taskType===3">
      <!-- Step 1 -->
      <div>
        <!-- Step 1 Head -->
        <div @click="showTypeContent=3" class="step-box-header" v-if="taskType===-1||taskType===3">
          4. RNA Modeling with NMR
          <div class="step-box-button">
            <a v-if="taskType===-1" @click="taskType=3">Choose this task</a>
            <a v-if="taskType===3" @click="taskType=-1">Choose a different task</a>
          </div>
        </div>

        <!-- Step 1 Content -->
        <div class="step-box-content" v-if="taskType===3||showTypeContent===3">
          Refinement of RNA structure using NMR xplor constraints.
        </div>
      </div>

      <!-- Step 2 -->
      <div v-if="taskType===3">
        <h4>Step 2: Please fill these inputs:</h4>
        <div class="step-table">
          <table>
            <tr><td style="width:60%">Task title (Optional)</td><td><input v-model="taskConfig[3].title"></td></tr>
            <tr><td style="width:60%">RNA Sequence (A/C/G/U only):</td><td><input v-model="taskConfig[3].seq">
                <p><b>Example</b>: ACGUACCGU</p>
                <p>Only 'A', 'U', 'G', 'C' are accepted. <span style="color:#b10808">'T' is not accepted!!!</span></p>
                <p>Lowercase is not accepted.</p>
            </td></tr>
            <tr>
              <td>RNA Secondary Structure (Optional)</td>
              <td><input v-model="taskConfig[3].ss">
                <p>Please use Dot-bracket Notation. All parentheses and brackets should be paired. Pseudo-knots are supported.</p>
                <p>
                  <b>Correct Example 1</b>: (((...))) <br>
                  <b>Correct Example 2</b>: (((((..[[[)))))..]]]<br>
                  <b style="color:#b10808">Wrong Example</b>: (((...]]]
                </p>
              </td>
            </tr>
            <tr><td>Upload NMR .mr File:</td><td><input type="file" ref="nmr">
                <p>Click <b>here</b> to download example NMR file.</p>
            </td></tr>
            <tr><td>Upload Hoogsteen Base Pairing File (optional):</td><td><input type="file"></td></tr>
            <tr><td>Upload Reverse Watson-Crick Base Pairing File (optional):</td><td><input type="file"></td></tr>
            <tr><td>Upload Reverse Hoogsteen Base Pairing File (optional):</td><td><input type="file"></td></tr>
            <tr><td>Simulation Time:</td><td><input v-model="taskConfig[3].simTime"></td></tr>
            <tr><td>Replica 1 Temperature (DMD units):</td><td><input v-model="taskConfig[3].repT[0]"></td></tr>
            <tr><td>Replica 2 Temperature (DMD units):</td><td><input v-model="taskConfig[3].repT[1]"></td></tr>
            <tr><td>Replica 3 Temperature (DMD units):</td><td><input v-model="taskConfig[3].repT[2]"></td></tr>
            <tr><td>Replica 4 Temperature (DMD units):</td><td><input v-model="taskConfig[3].repT[3]"></td></tr>
            <tr><td>Replica 5 Temperature (DMD units):</td><td><input v-model="taskConfig[3].repT[4]"></td></tr>
            <tr><td>Replica 6 Temperature (DMD units):</td><td><input v-model="taskConfig[3].repT[5]"></td></tr>
            <tr><td>Replica 7 Temperature (DMD units):</td><td><input v-model="taskConfig[3].repT[6]"></td></tr>
            <tr><td>Replica 8 Temperature (DMD units):</td><td><input v-model="taskConfig[3].repT[7]"></td></tr>
            <tr><td>Replica Exchange Interval (DMD units):</td><td><input v-model="taskConfig[3].repInt"></td></tr>
            <tr><td>Heat Exchange Coefficient (Berendsen Thermostat, DMD Units):</td><td><input v-model="taskConfig[3].heatExchCoeff"></td></tr>
          </table>
        </div>
      </div>

    </div>

    <div v-if="taskType!==-1" style="margin:15px 0px">
      <button @click="submit" type="button">Submit Task</button>
    </div>
  </div>
</div>
</template>

<script>
import { bus } from '../bus.js'
import axios from 'axios'

export default {
  name: 'Submit',

  data () {
    return {
      errors: [],
      showTypeContent: 0,
      taskType: -1,
      taskConfig: [
        {
          title: '',
          seq: '',
          ss: '',
          simTime: 20000,
          repT: [0.200, 0.225, 0.250, 0.270, 0.300, 0.333, 0.367, 0.400],
          repInt: 1000,
          heatExchCoeff: 0.1,
          predStruct: true
        }, {
          title: '',
          simTime: 20000,
          repT: [0.200, 0.225, 0.250, 0.270, 0.300, 0.333, 0.367, 0.400],
          repInt: 1000,
          heatExchCoeff: 0.1
        }, {
          title: '',
          seq: '',
          ss: '',
          simTime: 20000,
          repT: [0.200, 0.225, 0.250, 0.270, 0.300, 0.333, 0.367, 0.400],
          repInt: 1000,
          heatExchCoeff: 0.1
        }, {
          title: '',
          seq: '',
          ss: '',
          simTime: 20000,
          repT: [0.200, 0.225, 0.250, 0.270, 0.300, 0.333, 0.367, 0.400],
          repInt: 1000,
          heatExchCoeff: 0.1
        }
      ],
      content: ''
    }
  },

  computed: {
    frontConfig () {
      return this.$store.state.frontConfig
    }
  },

  methods: {
    post (url, p, files, cb1 = () => {}, cb2 = () => {}) {
      let parts = [new Blob([JSON.stringify(p)], { type: 'application/json' })]
      let fileToSend = new File(parts, 'front-config.json', {
        type: 'application/json'
      })

      let formData = new FormData()

      formData.append('par', fileToSend)
      for (let fileName in files) {
        console.log(fileName)
        console.log(files[fileName])
        formData.append(fileName, files[fileName])
      }

      axios({
        method: 'post',
        url,
        data: formData,
        config: {headers: {'Content-Type': 'multipart/form-data'}},
        withCredentials: true
      }).then(response => {
        cb1(response)
//        console.log(response)
      }).catch(error => {
        cb2(error)
//        console.log(error.response)
      })
    },

    submit () {
      let v = this
      if (v.taskType !== -1) {
        let p = {}
        for (let i in v.taskConfig[v.taskType]) {
          p[i] = v.taskConfig[v.taskType][i]
        }
        p.taskType = v.taskType

        let url = v.$store.state.host + `/dokhlab/actions/ifoldrna/submit`

        let files = {}

        if (v.taskType === 1) {
          if (v.$refs.pdb.files.length > 0) {
            files['pdb'] = v.$refs.pdb.files[0]
          } else {
            alert('Please upload the structure file!')
            return
          }
        } else if (v.taskType === 2) {
          if (v.$refs.hrp.files.length > 0) {
            files['hrp'] = v.$refs.hrp.files[0]
          }
        } else if (v.taskType === 3) {
          if (v.$refs.nmr.files.length > 0) {
            files['nmr'] = v.$refs.nmr.files[0]
          }
        }

        v.post(url, p, files, response => {
          let r = response.data
          if (r.status === 1) {
            window.location.href = '#/Queue'
          } else {
            alert('Submission Failed!')
          }
        })
      }
    },

    resizeIframe (obj) {
      console.log(this.$refs.frame)
      this.$refs.frame.style.height = this.$refs.frame.contentWindow.document.body.scrollHeight + 'px'
    }
  },

  mounted () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.step-box-header {
  background-color: rgb(187, 187, 187);
  color: rgb(51, 51, 51);
  font: bold 12px "Trebuchet MS", Arial;
  padding: 5px;
  cursor: pointer;
  height: 20px;
}

.step-box-content {
  border-color: rgb(187, 187, 187);
  border-style: solid;
  border-width: 1px;
  border-image: none 100% / 1 / 0 stretch;
  margin: 0px;
  overflow: visible;
  padding: 4px;
  font-size: 12px;
  min-height: 100px;
}

.step-table table {
  border-color: rgb(187, 187, 187);
  border-style: solid;
  border-width: 1px;
  table-layout: fixed;
  width: 100%;
}

.step-table table td {
  font-size: 12px;
  color: #686868;
  border-color: rgb(187, 187, 187);
  border-style: solid;
  border-width: 1px;
  padding: 4px;
}

.step-box-button {
  float: right;
  border: 1px outset #111111;
  width: 150px;
  text-align: center;
  padding: 0 0 0 4px;
  text-align: center;
  color: rgb(51, 51, 51);
  font: bold 12px "Trebuchet MS", Arial;
}

.step-box-button a {
  color: rgb(51, 51, 51);
}

.step-box-button:hover {
  border: 1px inset #111111;
}

.input-example {
  color: #b10808;
}

.error-row {
  margin-bottom: 15px;
}

input {
  width: 100%;
}

</style>
