<template>

<div style="margin:0px auto;width:750px">
  <!-- Top -->
  <div style="margin-bottom:25px;">
    <div style="background-color:#d3d3d3;width:100%;height:50px;line-height:50px;">
      <span style="margin-left:10px; font-family: 'Times New Roman', Times, serif; font-size:27px;color:#707070">iFoldRNA v2</span>
      <div style="float:right;height:100%;color:#a5a5a5;margin-right:10px">
        <b>Dokholyan Group</b>
      </div>
      <div style="clear:both">
      </div>
    </div>

    <div style="border-bottom: 1px solid #111111;background: #f1f1f1;padding: 2px 2px 4px 2px;" v-if="$store.state.user!=''">
      Logged in as {{$store.state.user.username}} | <a style="color:#810808;cursor:pointer" @click="logout">Logout</a>
    </div>
  </div>

  <!-- Main -->
  <div>
    <!-- Unlogin -->
    <div style="font: normal normal 13px 'helvetica', georgia, arial" v-if="$store.state.user==''">
      <div style="float:left;width:550px">
        <div style="margin-bottom:15px">
          <b>Welcome to iFoldRNA Ver 2.0</b> - a web portal for interactive RNA folding simulations. We perform discrete molecular dynamics simulations of RNA using coarse-grained structural models (three-beads/residue).
        </div>

        <div style="margin-bottom:15px;border: 2px solid #999999; background-color: #EEEEEE; margin-right: 10px; padding: 5px;">
          <b>Important note</b>: Please visit the Help Center before submitting your RNA foldig jobs. Especially, please read the section of "Choosing iFoldRNA simulation-time" for the appropraite simulation time.
        </div>

        <div style="margin-bottom:15px;">
          To cite iFoldRNA in your research, please use the following references:
          <ul>
            <li>
              A. Krokhotin, K. Houlihan, and N. V. Dokholyan, "iFoldRNA v2: folding RNA with constraints" <i>Bioinformatics</i>, 31: 2891-2893 (2015).<br>
            </li>

            <li>
              S. Sharma, F. Ding, and N. V. Dokholyan, "iFoldRNA:Three-dimensional RNA structure prediction and folding" <i>Bioinformatics</i>, 24: 1951-1952 (2008).<br>
            </li>

            <li>
              F. Ding, S. Sharma, P. Chalasani, V. V. Demidov, N. E. Broude, and N. V. Dokholyan, "Large scale simulations of 3D RNA folding by discrete molecular dynamics: From structure prediction to folding mechanisms" <i>RNA</i>, 14: 1164-1173 (2008).<br>
            </li>
          </ul>
        </div>

        <div style="margin-bottom:15px;">
          We recommend you to carefully read the Terms of Use and the Privacy Policy. If you have any questions, please contact the <a href="mailto:juw1179@psu.edu?cc=dokh@psu.edu&Subject=iFoldRNA%20Problem">iFoldRNA Team</a>.
        </div>

        <div style="margin-bottom:15px;">
          <fieldset>
            <legend>How good is your RNA model?</legend>

            <p>Submit the following form to estimate significance of RNA structure prediction</p>

            <div style="text-align:center">
              <table style="table-layout:fixed;width:250px;margin:10px auto">
                <tr><td style="width:150px;text-align:right">Length of the RNA molecule:</td><td><input style="width:50px" v-model="form.length"></td></tr>
                <tr><td style="text-align:right">Predicted RMSD:</td><td><input @keyup.enter="calcRnaPredSignificance" style="width:50px" v-model="form.rmsd"></td></tr>
              </table>

              <button type="button" @click="calcRnaPredSignificance">Submit</button>

              <div style="margin-top:20px;" v-html="significance"></div>
            </div>
          </fieldset>
        </div>
      </div>
      <div style="float:left;width:200px">
        <div style="margin-bottom:30px">
          <table>
            <tr><td style="width:100px">Username:</td><td><input style="width:100px" @keyup.enter="login" v-model="userLogin.username"></td></tr>
            <tr><td>Password:</td><td><input style="width:100px" @keyup.enter="login" type="password" v-model="userLogin.password"></td></tr>
          </table>
          <button @click="login" type="button">Login</button>
        </div>

        <div>
          <fieldset>
            <legend>Register Account</legend>
            <table>
              <tr><td style="width:100px">Username:</td>     <td><input style="width:100px" v-model="userRegister.username"></td></tr>
              <tr><td>Password:</td>     <td><input type="password" style="width:100px" v-model="userRegister.password1"></td></tr>  
              <tr><td>Confirm Pass:</td> <td><input type="password" style="width:100px" v-model="userRegister.password2"></td></tr>
              <tr><td>First Name:</td>   <td><input style="width:100px" v-model="userRegister.firstname"></td></tr>
              <tr><td>Last Name:</td>    <td><input style="width:100px" v-model="userRegister.lastname"></td></tr>
              <tr><td>Organization:</td> <td><input style="width:100px" v-model="userRegister.affiliation"></td></tr>
              <tr><td>Email:</td>        <td><input style="width:100px" v-model="userRegister.email"></td></tr>
            </table>
            <div style="text-align:center;margin-top:15px">
              <button @click="signUp" type="button">Register An Account</button>
            </div>
          </fieldset>
        </div>

      </div>
    </div>

    <!-- Login -->
    <div v-if="$store.state.user!=''">
      <!-- Nav -->
      <div style="float:left;width:155px">
        <div class="nav-menuitem">
          <a href="#/submit">
            <img src="./assets/nav_submit.jpg">
          </a>
        </div>

        <div class="nav-menuitem">
          <a href="#/profile">
            <img src="./assets/nav_profile.jpg">
          </a>
        </div>

        <div class="nav-menuitem">
          <a href="#/queue">
            <img src="./assets/nav_queue.jpg">
          </a>
        </div>

        <div class="nav-menuitem">
          <a href="#/help">
            <img src="./assets/nav_help.jpg">
          </a>
        </div>

        <div v-if="$store.state.user.level==='10'" class="nav-menuitem">
          <a href="#/users">
            <img src="./assets/nav_manageusers.jpg">
          </a>
        </div>

      </div>

      <div style="float:left;width:595px">
        <keep-alive include="Submit">
          <router-view/>
        </keep-alive>
        <!--
        <router-view/>
        -->
      </div>
    </div>
  </div>

</div>
</template>

<script>
import { bus } from './bus.js'
// import Login from './components/Login'
import axios from 'axios'

function calculateQValues(rnaLength, rmsd) {
  const ITMAX = 100
  const EPS = 3.0e-7
  function gammln(xx) {
    let x, tmp, ser
    const cof = [76.18009173, -86.50532033, 24.01409822, -1.231739516, 0.120858003e-2, -0.536382e-5]
    let j

    x = xx - 1.0
    tmp = x + 5.5
    tmp -= (x + 0.5) * Math.log(tmp)
    ser = 1.0
    for (j = 0; j <= 5; j++) {
      x += 1.0
      ser += cof[j] / x
    }
    return -tmp + Math.log(2.50662827465 * ser)
  }

  function gser(a, x) {
    let n
    let sum = 0.0, del, ap

    const gln = gammln(a)
    if (x <= 0.0) {
      if (x < 0.0) console.error('x less than 0 in routine GSER')
      return 0.0
    } else {
      ap = a
      del = sum = 1.0 / a
      for (n = 1; n <= ITMAX; n++) {
        ap += 1.0
        del *= x / ap
        sum += del
        if (Math.abs(del) < Math.abs(sum) * EPS) {
          return sum * Math.exp(-x + a * Math.log(x) - gln)
        }
      }
      console.error('a too large, ITMAX too small in routine GSER')
      return 0.0
    }
  }

  function gcf(a, x) {
    let n
    let gold = 0.0, g, fac = 1.0, b1 = 1.0
    let b0 = 0.0, anf, ana, an, a1, a0 = 1.0

    const gln = gammln(a)
    a1 = x
    for (n = 1; n <= ITMAX; n++) {
      an = n
      ana = an - a
      a0 = (a1 + a0 * ana) * fac
      b0 = (b1 + b0 * ana) * fac
      anf = an * fac
      a1 = x * a0 + anf * a1
      b1 = x * b0 + anf * b1
      if (a1) {
        fac = 1.0 / a1
        g = b1 * fac
        if (Math.abs((g - gold) / g) < EPS) {
          return Math.exp(-x + a * Math.log(x) - gln) * g
        }
        gold = g
      }
    }
    console.error('a too large, ITMAX too small in routine GCF')
    return 0.0
  }

  function gammp(a, x) {
    const gamser = gser(a, x)
    if (x < (a + 1.0)) {
      return gamser
    } else {
      const gammcf = gcf(a, x)
      return 1.0 - gammcf
    }
  }

  function erf(x) {
    return x < 0.0 ? -gammp(0.5, x * x) : gammp(0.5, x * x)
  }

  const a_wo = 4.6 * Math.sqrt(2.0), b_wo = 9.1 * Math.sqrt(2.0)
  const a_w = 3.6 * Math.sqrt(2.0), b_w = 11.2 * Math.sqrt(2.0)
  const std = 1.8

  const ave_wo = a_wo * Math.pow(rnaLength, 0.41) - b_wo
  const ave_w = a_w * Math.pow(rnaLength, 0.41) - b_w

  const z_wo = (rmsd - ave_wo) / std
  const z_w = (rmsd - ave_w) / std
  const sqrt2 = Math.sqrt(2.0)

  const q_wo = (1.0 + erf(z_wo / sqrt2)) / 2.0
  const q_w = (1.0 + erf(z_w / sqrt2)) / 2.0

  return { q_wo, q_w }
}


export default {
  name: 'app',

  data () {
    return {
      form: {
        length: '',
        rmsd: ''
      },
      userLogin: {
        username: '',
        password: ''
      },
      userRegister: {
        username: '',
        password1: '',
        password2: '',
        organization: '',
        email: ''
      },
      formLabelWidth: 150,
      userVisible: false,
      significance: '',
    }
  },

  computed: {
    frontConfig () {
      return this.$store.state.frontConfig
    }
  },

  components: {
  },

  methods: {
    
    redirect (url) {
      window.location.href = url
    },

    login () {
      let v = this
      v.$store.dispatch('login', { username: v.userLogin.username, password: v.userLogin.password }).then(() => {
        window.location.href = '#/Submit'
      })
    },

    logout () {
      let v = this
      v.$store.dispatch('logout')
    },

    signUp () {
      let v = this
      v.$store.dispatch('signUp', v.userRegister).then(() => {
        window.location.href = '#/Submit'
      })
    },

    calcRnaPredSignificance () {
      let v = this

      const { q_wo, q_w } = calculateQValues(v.form.length, v.form.rmsd);
      let i = parseFloat(q_wo).toExponential(3)
      let j = parseFloat(q_w).toExponential(3)
      v.significance = `Without base pair constraints:${i}<br> With base pair constraints:${j}`
    }
  },

  mounted () {
    var v = this

    bus.$on('switch-router', (name) => {
      v.active = name
    })
    bus.$on('switch-task', (id) => {
      v.task_id = id
    })
    var top = (document.body.clientHeight - 600) / 2
    var left = (document.body.clientWidth - 1000) / 2
    v.top = (top < 0 ? 0 : top)
    v.left = (left < 0 ? 0 : left)

    v.$store.commit('setkv', {k:'server',v:'ifoldrna'})

    // Auto-Login
    v.$store.dispatch('autoLogin')
  }
}
</script>

<style>
html {
  overflow-y: scroll;
  font-size: 12px;
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  /*
  background: url('./assets/app-bg.jpg') no-repeat 50%;
  */
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  background: #d6d6d6;
  border: 1px solid #A7A7A7;
  color: #111111;
}

textarea {
  background: #d6d6d6;
  border: 1px solid #A7A7A7;
  color: #111111;
}

.nav-menuitem {
  margin: 10px 0px;
}

img {
  margin: 0px;
}

body {
  margin: 8px;
  padding: 0px;
}

#app {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  position: relative;
}

table {
  height: 100%;
  width: 100%;
  border-collapse:collapse;
  overflow: hidden;
}

tr {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.nav {
  height: 100%;
  width: 250px;
  min-width: 250px;
  background: #2e3238;
  color: white;
  padding: 0px;
  vertical-align: text-top;
}

.nav-header {
  margin: 20px;
  line-height: 40px;
  vertical-align: middle;
}

.content {
  background-color: white;
  align: center;
  padding: 0px;
  overflow-y: auto;
  position: relative;
  /*
  background-image: url('./assets/intro-bg.png');
   */
background-repeat: no-repeat;
background-position: 0px 0px;
background-size: auto 220px;
}

.content-content {
  width: 720px;
  height: 600px;
  margin: 0px auto;
}

/*
.zoom {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 18px;
  color: #878d99;
  cursor: pointer;
  z-index: 99;
}
 */

.left-nav {
  margin-bottom: 0px;
}

.box-card {
  padding: 0px 0px;
}

a {
  text-decoration: None;
}

li.menu-item {
  padding: 0 20px;
  font-size: 14px;
  /*color: #2d2f33;*/
  height: 56px;
  line-height: 56px;
  cursor: pointer;
}

.submenu-list li.menu-item {
  padding: 0 40px;
}

li.menu-item:hover {
  /*background-color: #ecf5ff*/
}

li.menu-item.is-active {
  /*
  color: #ffd04b;
   */
font-weight: bold;
color: #67c23a;
}

li.menu-item * {
  vertical-align: middle;
}

li.menu-item i {
  margin-right: 5px;
  width: 24px;
  text-align: center;
  font-size: 18px;
  color: #878d99;
}

li.menu-item.is-active i {
  color: inherit;
}

.submenu-title {
  position: relative;
}

li.menu-item i.submenu-arrow {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -7px;
  font-size: 12px;
}

#bg {
  margin: 0px 0px 10px 0px;
}

div.el-card__header {
  padding: 0px 20px;
  /*
  background-color: #223e4a;
  color: white;
   */
}

.grey-button {
  color: #5a5e66;
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  color: rgb(144, 147, 153);
}

a:hover {
  color: rgb(144,147,153);
}

a:visited {
  color: rgb(144, 147, 153);
}

.el-button--text {
  color: #67c23a;
}

</style>

