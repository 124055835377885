<template>
<div class="task">
  <div class="task-panel" v-if="task">
    <el-row class="task-row">
      <el-col :span="6" class="item-label">ID</el-col>
      <el-col :span="16">{{task.id}}</el-col>
    </el-row>

    <el-row class="task-row">
      <el-col :span="6" class="item-label">Type</el-col>
      <el-col :span="16">{{taskTypeName(task.taskType)}}</el-col>
    </el-row>

    <el-row class="task-row">
      <el-col :span="6" class="item-label">Status</el-col>
      <el-col :span="16">{{task.status}}</el-col>
    </el-row>

    <el-row class="task-row" v-if="task.seq">
      <el-col :span="6" class="item-label">Sequence</el-col>
      <el-col :span="24">{{task.seq}} ({{task.seq.length}})</el-col>
    </el-row>

    <el-row class="task-row" v-if="task.ss">
      <el-col :span="6" class="item-label">Secondary Structure</el-col>
      <el-col :span="24">{{task.ss}} ({{task.ss.length}})</el-col>
    </el-row>

    <el-row class="task-row" v-if="task.files.pdb">
      <el-col :span="6" class="item-label">Structure</el-col>
      <el-col :span="16"><a style="cursor:pointer" @click="download(task.files.pdb)">input.pdb</a></el-col>
    </el-row>

    <el-row class="task-row" v-if="task.status==='finished'">
      <el-col :span="6" class="item-label">Results</el-col>
      <el-col :span="24">
        <el-row v-for="cluster in parseInt(task.results.clusters)" :key="cluster">
          <el-col :offset="4" :span="4">Cluster {{cluster}}</el-col>
          <el-col :span="4"><a :href="$store.state.host + `/dokhlab/actions/ifoldrna/cluster?id=${id}&cluster=${cluster}`" target="_blank">cluster.{{cluster}}.pdb</a></el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-row class="task-row" v-if="task.status==='failed'">
      <el-col :span="6" class="item-label">Log</el-col>
      <el-col :span="16"><a :href="$store.state.host + `/dokhlab/actions/ifoldrna/log?id=${id}`">log.txt</a></el-col>
    </el-row>

  </div>
  <div v-else>
    Loading<i class="el-icon-loading"></i>
  </div>
</div>
</template>

<script>
import axios from 'axios'
// import * as NGL from 'ngl'
// import { bus } from '../bus.js'

axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'

export default {
  name: 'Task',

  data () {
    return {
      task: ''
    }
  },

  computed: {
    id () {
      return this.$route.params.id
    }
  },

  methods: {
    taskTypeName (i) {
      let names = [
        'RNA modeling with no restraints',
        'Thermodynamic analysis',
        'RNA modeling with HRP restraints',
        'RNA modeling with NMR restraints'
      ]
      return names[i]
    },

    download (content) {
      var blob = new Blob([content], { type: 'text/plain;charset=utf-8;' })

      var link = document.createElement('a')
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', 'input.pdb')
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },

    check () {
      var v = this
      axios({
        method: 'get',
        url: v.$store.state.host + '/dokhlab/actions/ifoldrna/task?id=' + v.id,
        withCredentials: true
      }).then(response => {
        v.task = response.data

        if (v.task.par) {
          v.task.par = v.task.par ? JSON.parse(v.task.par) : ''
        }
        if (v.task.files) {
          v.task.files = v.task.files ? JSON.parse(v.task.files) : ''
//          console.log(v.task.files)
        }
        if (v.task.results) {
          v.task.results = v.task.results ? JSON.parse(v.task.results) : ''
        }

//        console.log(v.task)

        if (!v.task.par) {
          v.task.par = JSON.parse(v.task.files.par)
        }

        console.log(JSON.stringify(v.task.results))

        for (let i in v.task.par) {
          v.task[i] = v.task.par[i]
        }

        if (v.task.status !== 'finished') {
          v.timeout = setTimeout(() => { v.check() }, 5000)
        }
      }).catch(() => {
        console.log('Retrieve files failed!')
      })
    }
  },

  mounted () {
    var v = this
    v.$nextTick(function () {
//      v.stage = new NGL.Stage('b-factor', { backgroundColor: 'white' })

      v.check()
    })
  },

  destroyed () {
    var v = this
    if (v.timeout) {
      clearTimeout(v.timeout)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.task-panel {
  overflow-wrap: break-word;
  font-size: 12px;
  margin-top: 20px;
}

.task-row {
  margin-bottom: 20px;
}

.item-label {
  font-weight: 700;
  margin-bottom: 5px;
}

</style>
