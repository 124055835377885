<template>
<div>
  <div style="border-bottom: 2px dotted #111111;font: normal normal 18px 'trebuchet ms', georgia, arial;color: #686667;margin-bottom:30px">
    Help
  </div>
  <p class="section-title">General</p>
  <div class="section-content">
    <p>The iFoldRNA is a physical simulation-based automated RNA structure prediction webserver designed to predict three-dimensional RNA structure based on available sequence data. The server operates in three different modes: </p>

    <p>1) RNA modeling. In this mode the server predicts RNA tertiary structure solely based on the nucleotide sequence provided. This mode works for RNAs, which are less than 50 nucleotides long. The predicted structures are typically within 4A Root-Mean-Square deviation (RMSD) from experimentally determined structures. This limitation on RNA size is due to inaccuracies in the force-field and insufficient sampling. Increasing sequence size quickly becomes computationally prohibitive, since the RNA conformational space grows exponentially.</p>

    <p>2) Thermodynamic analysis.  In this mode, various thermodynamic properties of RNA folding are inferred using replica-exchange DMD simulations. </p>

    <p>3) RNA modeling using HRP restraints. In this mode, a user can provide nucleotide solvent accessibility measured from reactivities from hydroxyl radical probing experiments as restraints. By use of experimental restraints, sampling conformational space can be significantly reduced, which allows us to predict the tertiary structure of RNAs up to a few hundred nucleotides long (for more details see "Structure refinement using Constraints" section). </p>

    <p>4) RNA modeling using NMR restraints. In this mode, a user can provide NMR restraints. </p>
  </div>

  <p class="section-title">RNA Modeling Using Constraints</p>
  <div class="section-content">
    <p>Experimental restraints can be used to restrict RNA conformational space sampled to predict RNA tertiary structure. The current version of iFoldRNA webserver works only with three types of restraints: base-pairing information, nucleotide solvent accessibility, and NMR restraints. Base-pairing is provided as a list of base pairs, which can be derived from sequence covariation analysis or using SHAPE technique (Low and Weeks. "SHAPE-directed RNA secondary structure prediction." Methods 52.2 (2010): 150-158). Nucleotide solvent accessibility is provided as a list of reactivities measured from hydroxyl radical probing (HRP) experiments (Ding, et al. "Three-dimensional RNA structure refinement by hydroxyl radical probing." Nature methods 9.6 (2012): 603-608).</p>

    <p>The webserver interface is self-explanatory. Examples are provided for each mode. </p>
  </div>

  <p><b>Please contact juw1179@psu.edu if further help is needed.</b></p>
</div>

</template>

<script>
// import { bus } from '../bus.js'
// import axios from 'axios'

export default {
  name: 'Help',

  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.section-title {
  color: #B10808;
  font-size: 14px;
  font-weight: bold;
}

.section-content {
  font-size: 12px;
  line-height: 150%;
}

</style>

