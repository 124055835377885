import Vue from 'vue'
import axios from 'axios'

export default {
  state: {
    user: '',
    server: '',
    host: '//' + location.hostname,
    view: 'fullscreen',
    scrollTop: 0,
    history: [],
    itemsPerPage: 5
  },

  mutations: {
    setkv (state, {k, v}) {
      state[k] = v
    },

    setUser (state, v) {
      state.user = v
    },

    viewFullscreen (state) {
      state.view = 'fullscreen'
    },

    viewWindow (state) {
      state.view = 'window'
    },

    setScrollTop (state) {
      state.scrollTop = document.documentElement.scrollTop
    },

    historyPush (state, val) {
//      console.log(`history push ${val}`)
//      console.log(`history:${state.history}`)
      state.history.push(val)
//      console.log(`history:${state.history}`)
    },

    historyPop (state) {
      if (state.history.length > 0) {
//        console.log(`history pop`)
//        console.log(`history:${state.history}`)
        state.history.pop()
//        console.log(`history:${state.history}`)
      }
    }
  },

  actions: {
    direct ({commit}, url) {
      commit('historyPush', url)
      window.location.href = url
    },

    redirect ({commit}, url) {
      commit('historyPop')
      commit('historyPush', url)
      window.location.href = url
    },

    back ({commit, state, dispatch}, or) {
      let n = state.history.length
//      console.log('back')
      if (n > 1) {
//        console.log(`n:${n}`)
        let url = state.history[n - 2]
//        console.log(`url:${url}`)
        commit('historyPop')
//        console.log(`url:${url}`)
        window.location.href = url
      } else if (or) {
//        console.log(`or:${or}`)
        dispatch('direct', or)
      }
    },

    autoLogin ({commit, state, dispatch}, cb = () => {}) {
      axios({
        method: 'get',
        url: state.host + '/dokhlab/actions/user',
        withCredentials: true
      }).then(response => {
        var r = response.data
        if (r.status === 1) {
          commit('setUser', r.user)
          cb()
        }
      }).catch(error => {
        alert(error.response.headers)
      })
    },

    login ({commit, state, dispatch}, {username, password}) {
      let formData = new FormData()
      formData.append('username', username)
      formData.append('password', password)

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: state.host + '/dokhlab/actions/login',
          data: formData,
          config: {headers: {'Content-Type': 'multipart/form-data'}},
          withCredentials: true
        }).then(response => {
          var r = response.data
          if (r.status === 1) {
            commit('setUser', r.user)
            resolve()
          } else {
            reject(response.data)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },

    logout ({commit, state, dispatch}) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: state.host + '/dokhlab/actions/logout',
          withCredentials: true
        }).then(response => {
          commit('setUser', '')
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    },

    signUp ({commit, state, dispatch}, par) {
      let formData = new FormData()
      for (let i in par) {
        formData.append(i, par[i])
      }

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: state.host + '/dokhlab/actions/sign_up',
          data: formData,
          config: {headers: {'Content-Type': 'multipart/form-data'}},
          withCredentials: true
        }).then(response => {
          var r = response.data
          if (r.status === 1) {
            commit('setUser', r.user)
            resolve()
          } else {
            reject(r.msg)
          }
        }).catch(err => {
          reject(err)
        })
      })
    }
  },

  getters: {
  }
}
